<template>
  <div class="width-100">
    <p class="fs-super-big black fw-mid width-100 margin-b">我的收藏</p>
    <div v-if="list.length > 0" class="main-box">
      <el-row :gutter="30">
        <el-col :span="6" v-for="(item, index) in list" :key="index">
          <div
            class="item bg-white position-relative pointer"
            @click="$router.push(`/copyrightDisplayDetail?id=${item.copyrightRegistration.id}`)"
            @mouseover="item.ifShow = true"
            @mouseleave="item.ifShow = false"
          >
            <van-image v-if="item.copyrightRegistration" class="image" :src="item.copyrightRegistration.copyrightImage" width="100%"></van-image>
            <p class="padding-Tb-10 padding-lr fs-mid black width-100 cut-text fw-mid">{{ item.copyrightRegistration.title }}</p>
            <p class="padding-lr fs-mid darkgrey width-100 cut-text opacity-8">{{ item.copyrightRegistration.copyrightOwnerName }}</p>
            <p class="padding-lr margin-ts fs-mid theme-blue width-100 cut-text">
              {{ item.copyrightRegistration.copyrightPriceType === '0' ? item.copyrightRegistration.copyrightPrice : '价格面议' }}
            </p>
            <div
              class="favorite-box box-shadow position-absolute pointer"
              :class="item.ifShow ? 'opacity-1 fadeIn' : 'opacity-0'"
              @click.stop="favorites(item.ifFavorites, index)"
            >
              <img v-if="!item.ifFavorites" src="../assets/image/s1.png" alt="" />
              <img v-else src="../assets/image/s2.png" alt="" />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else class="width-100 flex-column justify-content-center align-items-center">
      <img src="../assets/image/empty.png" width="50%" />
    </div>
    <el-dialog :visible.sync="dialogVisible" width="30%" :show-close="false">
      <div class="dialog width-100 flex-column justify-content-center align-items-center">
        <i class="el-icon-warning"></i>
        <span class="fs-big black">确定要取消版权的收藏？</span>
        <span slot="footer" class="dialog-footer">
          <el-button round type="primary" @click="cancel">确 定</el-button>
          <el-button type="primary" plain round @click="dialogVisible = false">取 消</el-button>
        </span>
      </div>
    </el-dialog>
    <!--      分页-->
    <div class="pagination width-100 textAlign-c">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[30, 60, 100, 200]"
        :page-size="30"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getUserList, like } from '@/api/tranCopyrightCollect';

export default {
  name: 'myFavorites',
  data() {
    return {
      currentPage: 1,
      cancelIndex: -1,
      dialogVisible: false,
      list: [],
      params: {
        copyrightTypeOn: null,
        pageNum: 1,
        pageSize: 30
      },
      total: 0
    };
  },
  created() {
    this.getLike();
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.params.pageNum = 1;
      this.params.pageSize = val;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.params.pageNum = val;
      this.getLike();
    },

    favorites(e, m) {
      if (e) {
        this.dialogVisible = true;
        this.cancelIndex = m;
      } else {
        this.list[m].ifFavorites = true;
        this.clickLike();
      }
    },
    async getLike() {
      const liseList = await getUserList(this.params);
      this.list = liseList.rows.map((row) => {
        console.log(row)
        row.copyrightRegistration.copyrightImage = row.copyrightRegistration?.copyrightImage
          .split(',')
          .map((r) => `${process.env.VUE_APP_SERVE_PATH}${r}`)[0];
        return {
          ...row,
          ifShow: false,
          ifFavorites: true
        };
      });
      this.total = liseList.total;
    },
    cancel() {
      this.clickLike();
      this.list[this.cancelIndex].ifFavorites = false;
      this.dialogVisible = false;
    },
    async clickLike() {
      await like(this.list[this.cancelIndex].copyrightRegistration.id);
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .container-box {
    padding: 40px 0 20px;

    .item {
      width: 100%;
      height: 360px;
      box-shadow: 0 0 8px rgba(133, 188, 240, 0.25);

      border-radius: 10px !important;
      overflow: hidden;
    }

    .image {
      height: 230px;
    }
    .favorite-box {
      top: 10px;
      right: 10px;
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
      img {
        width: 24px;
      }
    }
    .favorite-box:hover {
      zoom: 1.5;
    }
  }
  .dialog {
    padding: 40px 0;
    border-radius: 15px;
    background: #fff;
    .el-icon-warning {
      font-size: 80px;
      color: #469dff;
      margin-bottom: 20px;
    }
    .dialog-footer {
      margin-top: 20px;
    }
  }
  .el-dialog {
    background: rgba(0, 0, 0, 0);
    .el-button {
      margin: 0 20px;
      padding: 10px 30px;
    }
  }
  .el-row {
    margin-bottom: 20px !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  .el-col {
    margin-bottom: 30px;
  }

  .pagination {
    margin: 60px 0 80px;
  }
}
</style>
