<template>
  <div class="width-100">
    <div class="width-100 margin-b flex-row justify-content-start align-items-center">
      <div class="width-70 flex-row justify-content-start align-items-center">
        <p class="fs-super-big black fw-mid">版权管理</p>
        <div v-if="detailId === -1" class="search-box flex-row justify-content-start align-items-center">
          <el-input v-model="input" placeholder="请输入关键字搜索"></el-input>
          <el-button class="search-btn" type="primary" round @click="getCopyrightData">搜索</el-button>
        </div>
      </div>
      <p v-if="detailId === -1" class="width-30 textAlign-r fs-mid darkgrey opacity-8">
        共{{ total }}个版权 <el-button class="el-button" @click="$router.push('/copyrightRegistration')" type="primary" plain round>新增+</el-button>
      </p>
    </div>
    <div class="main-box">
      <template v-if="detailId === -1">
        <div class="width-100 table-title flex-row justify-content-spaceBetween align-items-center">
          <p class="width-10 textAlign-c">状态</p>
          <div class="width-90 flex-row justify-content-spaceBetween align-items-center">
            <p class="width-40">版权信息</p>
            <p class="width-15 textAlign-c">授权方式</p>
            <p class="width-15 textAlign-c">版权类型</p>
            <p class="width-15 textAlign-c">审核状态</p>
            <p class="width-15 textAlign-c">操作</p>
          </div>
        </div>
        <div v-if="list.length > 0">
          <div class="table-body" v-for="(item, index) in list" :key="index">
            <div class="width-100 table-cell flex-row justify-content-spaceBetween align-items-star">
              <p class="width-10 textAlign-c padding-Tb-20">
                <el-checkbox v-model="item.copyrightCheck" @change="copyrightCheckChange">
                  <span class="tag" :class="item.copyrightPublic == 1 ? 'onSale' : 'unOnSale'">{{ item.copyrightPublic == 1 ? '在售' : '下架' }}</span>
                </el-checkbox>
              </p>
              <div class="width-90">
                <div class="width-100 flex-row justify-content-spaceBetween align-items-center">
                  <div class="width-40 pointer flex-row justify-content-around align-items-center" @click="detailId = index">
                    <el-image class="image" :src="item.picture" fit="cover"></el-image>
                    <div class="width-75">
                      <p class="fw-mid black">{{ item.title }}</p>
                      <p class="darkgrey opacity-8 fs-sml line-height2">版权用途：{{ item.copyrightUsageName }}</p>
                    </div>
                  </div>
                  <p class="width-15 textAlign-c black">{{ item.authorizationTypeName }}</p>
                  <p class="width-15 textAlign-c black">{{ item.copyrightTypeName }}</p>
                  <p v-if="item.audit == '0'" class="width-15 textAlign-c orange">审核中</p>
                  <p v-if="item.audit == '1'" class="width-15 textAlign-c black">审核通过</p>
                  <p v-if="item.audit == '2'" class="width-15 textAlign-c black">审核失败</p>
                  <p class="width-15 textAlign-c">
                    <template>
                      <span
                        v-if="item.audit == '1' && item.copyrightPublic == 1"
                        class="pointer theme-blue fs-mid"
                        @click="unpublicCopyrightFun(item.id)"
                        >下架</span
                      >
                      <span v-if="item.audit == '1' && item.copyrightPublic == 0" class="pointer theme-blue fs-mid" @click="publicCopyrightFun(item.id)"
                        >上架</span
                      >
                      <span class="margin-r margin-l pointer theme-blue fs-mid" @click="$router.push(`/copyrightRegistration?id=${item.id}`)"
                        >修改</span
                      >
                      <span class="pointer theme-blue fs-mid" @click="deleteTranCopyrightRegistrationById(item.id)">删除</span>
                    </template>
                  </p>
                </div>
  
                <el-collapse v-model="item.ifActive">
                  <el-collapse-item :title="'有意咨询人数 ' + item.wantBuyNum + '人'" :name="index">
                    <div
                      class="width-100 flex-row justify-content-spaceBetween align-items-center"
                      v-for="(wantBuy, ind) in item.wantBuyList"
                      :key="ind"
                    >
                      <p class="width-85">
                        <!-- <span class="margin-r">{{ wantBuy.company }}</span> -->
                        <span class="margin-r">{{ wantBuy.name }}</span>
                        <span class="margin-r">{{ wantBuy.phone }}</span>
                      </p>
                      <p class="width-15 textAlign-r">{{ wantBuy.createTime }}</p>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>
          <div class="width-100 menu-box flex-row justify-content-start align-items-center">
            <el-checkbox class="" v-model="checked" @change="clickAll"><span class="fs-mid grey">全选</span></el-checkbox>
            <!-- <div>
            </div> -->
            <el-button type="primary" plain @click="bulkUnpublic">上架</el-button>
            <el-button type="primary" plain @click="bulkPublic">下架</el-button>
            <el-button type="primary" plain @click="bulkDelete">删除</el-button>
          </div>
        </div>
        <div v-else class="width-100 flex-column justify-content-center align-items-center">
          <img src="../assets/image/empty.png" width="50%" />
        </div>
        <!--      分页-->
        <div class="pagination width-100 textAlign-c">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="5"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </template>
      <template v-else>
        <div class="detail width-100">
          <p class="fs-mid-big black fw-mid margin-b">{{ list[detailId].title }}</p>
          <p class="fs-mid darkgrey opacity-8">作者/来源：{{ list[detailId].copyrightOwnerName }}</p>
          <p class="fs-mid darkgrey opacity-8">证书编号：{{ list[detailId].copyrightNo }}</p>
          <p class="fs-mid darkgrey opacity-8">版权类型：{{ list[detailId].copyrightTypeName }}</p>
          <p class="fs-mid darkgrey opacity-8">授权方式：{{ list[detailId].authorizationTypeName }}</p>
          <p class="fs-mid darkgrey opacity-8">版权用途：{{ list[detailId].copyrightUsageName }}</p>
          <p class="fs-mid darkgrey opacity-8">授权范围：{{ list[detailId].authorizationScopeName }}</p>
          <p class="fs-mid-big black fw-mid margin-b">作品介绍</p>
          <p class="fs-mid darkgrey opacity-8">{{ list[detailId].instruction }}</p>
          <div class="picture-box textAlign-c width-100">
            <img class="margin-b" :src="item" v-for="(item, index) in list[detailId].pictures" :key="index" alt="" width="100%" />
          </div>
          <div class="width-100 flex-row justify-content-center align-items-center">
            <el-button type="primary" plain @click="publicCopyrightFunSigle(list[detailId].id)">上架</el-button>
            <el-button type="primary" plain @click="unpublicCopyrightFunSigle(list[detailId].id)">下架</el-button>
            <el-button type="primary" plain @click="deleteTranCopyrightRegistrationByIdSigle(list[detailId].id)">删除</el-button>
            <el-button type="primary" plain @click="$router.push(`/copyrightRegistration?id=${list[detailId].id}`)">修改</el-button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { myRegistrationList, deleteTranCopyrightRegistration, publicCopyright, unpublicCopyright } from '@/api/tranCopyrightRegistration';
import moment from 'moment';
import { getDict } from '@/api/dict';
import { getTextByValue } from '@/util/helper';

export default {
  name: 'copyrightManagement',
  components: {},
  props: {},
  data() {
    return {
      detailId: -1,
      checked: false,
      currentPage: 1,
      input: '',
      activeNames: ['1'],
      list: [],
      queryParams: {
        title: null,
        copyrightTypeOn: null,
        authorizationType: null,
        copyrightUsage: null,
        pageNum: 1,
        pageSize: 5,
        params: {
          myself: 'true',
          wantBuyList: 'true',
          searchText: null,
          sortRule: null
        }
      },
      total: 0,
      tranCopyrightUsageDist: [],
      tranAuthorizationTypeDist: [],
      tranAuthorizationScopeDist: [],
      PROJECT_BASE_URL: process.env.VUE_APP_SERVE_PATH
    };
  },
  // watch: {
  //   checked(newValue) {
  //     if (newValue) {
  //       this.list.forEach((row) => {
  //         row.copyrightCheck = true;
  //       });
  //     } else {
  //       this.list.forEach((row) => {
  //         row.copyrightCheck = false;
  //       });
  //     }
  //   }
  // },
  async created() {
    await this.setDist();
    this.getCopyrightData();
  },
  methods: {
    async setDist() {
      return Promise.all([getDict('tran_copyright_usage'), getDict('tran_authorization_type'), getDict('tran_authorization_scope')]).then((rows) => {
        this.tranCopyrightUsageDist = rows[0];
        this.tranAuthorizationTypeDist = rows[1];
        this.tranAuthorizationScopeDist = rows[2];
      });
    },
    rowClick(event) {
      // console.log(event.id);
      this.detailId = event.ind;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.queryParams.pageNum = 1;
      this.queryParams.pageSize = val;
      this.getCopyrightData();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.queryParams.pageNum = val;
      this.getCopyrightData();
    },
    async getCopyrightData() {
      if (this.input) {
        this.queryParams.params.searchText = this.input;
      } else if (this.queryParams.params.searchText) {
        this.queryParams.params.searchText = null;
      }
      const list = await myRegistrationList(this.queryParams);
      this.total = list.total;
      this.list = list.rows.map((row, ind) => {
        let pictures = null;
        if (row.copyrightImage) {
          pictures = row.copyrightImage.split(',').map(url => `${this.PROJECT_BASE_URL}${url}`)
        }
        return {
          ...row,
          ind,
          pictures,
          picture: row.copyrightImage ? `${this.PROJECT_BASE_URL}${row.copyrightImage.split(',')[0]}` : null,
          copyrightUsageName: getTextByValue(this.tranCopyrightUsageDist, row.copyrightUsage),
          authorizationTypeName: getTextByValue(this.tranAuthorizationTypeDist, row.authorizationType),
          authorizationScopeName: getTextByValue(this.tranAuthorizationScopeDist, row.authorizationScope),
          // ifActiveShow: !!(row.wantBuyList && row.wantBuyList.length > 0),
          ifActive: ['0'],
          copyrightCheck: false,
          wantBuyList: row.wantBuyList.map((r) => {
            return {
              ...r,
              createTime: moment(r.createTime).format('YYYY-MM-DD')
            };
          })
        };
      });
    },
    async publicCopyrightFun(id) {
      await publicCopyright(id);
      this.getCopyrightData();
    },
    async unpublicCopyrightFun(id) {
      await unpublicCopyright(id);
      this.getCopyrightData();
    },
    async deleteTranCopyrightRegistrationById(ids) {
      await deleteTranCopyrightRegistration(ids);
      this.getCopyrightData();
    },
    async publicCopyrightFunSigle(id) {
      await publicCopyright(id);
    },
    async unpublicCopyrightFunSigle(id) {
      await unpublicCopyright(id);
    },
    async deleteTranCopyrightRegistrationByIdSigle(ids) {
      await deleteTranCopyrightRegistration(ids);
      this.getCopyrightData();
      this.detailId = -1;
    },
    copyrightCheckChange() {
      if (this.list.find((row) => !row.copyrightCheck)) {
        this.checked = false;
      }else {
        this.checked = true;
      }
    },
    clickAll(check) {
      if (check) {
        this.list.forEach((row) => {
          row.copyrightCheck = true;
        });
      } else {
        this.list.forEach((row) => {
          row.copyrightCheck = false;
        });
      }
    },
    async bulkUnpublic() {
      const allUnpublic = this.list.filter(row => row.copyrightCheck).map(row => unpublicCopyright(row.id))
      await Promise.all(allUnpublic);
      this.getCopyrightData();
    },
    async bulkPublic() {
      const allPublic = this.list.filter(row => row.copyrightCheck).map(row => publicCopyright(row.id))
      await Promise.all(allPublic);
      this.getCopyrightData();
    },
    bulkDelete() {
      const ids = this.list.filter(row => row.copyrightCheck).map(row => row.id).join(',');
      this.deleteTranCopyrightRegistrationById(ids);
    }
  }
};
</script>

<style lang="less" scoped>
.el-icon-success {
  color: #5cb40e;
}
.image {
  width: 25%;
  height: 70px;
  margin-right: 3%;
}
.table-title {
  background: #f7f9fa;
  color: #898989;
  padding: 10px 0;
  p {
    font-size: 18px;
  }
}
.table-body {
  margin: 30px 0;
  p {
    font-size: 18px;
  }
}
.tag {
  padding: 2px 6px;
  border-radius: 20px;
  color: white;
  font-size: 14px;
}
.onSale {
  background: #4199ff;
}
.unOnSale {
  background: #cdcdcd;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner {
  background: #4199ff !important;
  border-color: #4199ff !important;
}
/deep/.el-collapse-item__header {
  background: #f3f9ff;
  margin-top: 10px;
  font-size: 16px;
  padding: 0 10px;
}
/deep/.el-collapse {
  border: none;
}
/deep/.el-collapse-item__content {
  background: rgba(243, 249, 255, 0.5);
  padding: 0 10px;
  p {
    margin: 5px 0;
    color: #333333;
  }
}
.menu-box {
  padding-left: 25px;
  margin-top: 60px;
  margin-bottom: 100px;
  .el-checkbox {
    margin-right: 20px;
  }

  .el-button {
    padding: 10px 30px;
  }
}

.el-button {
  margin-left: 10px;
  padding: 10px 15px;
  background: white;
  border: 1px solid #4199ff;
}
.search-box {
  border: 1px solid #eeeeee;
  border-radius: 50px;
  padding: 2px 4px;
  margin-left: 30px;
  /deep/.el-input__inner::placeholder {
    color: #999999 !important;
  }
  /deep/.el-input__inner {
    font-size: 18px;
  }
}
.search-btn {
  background: #4199ff;
  padding: 10px 30px;
}
.detail {
  p {
    line-height: 2rem;
    margin-bottom: 10px;
  }
  p.fs-mid-big {
    margin-top: 40px;
  }
  .el-button {
    padding: 10px 30px;
    margin: 40px 5px;
  }
}
</style>
