<template>
  <div class="width-100">
    <p class="fs-super-big black fw-mid width-100 margin-b">金融服务</p>
    <div class="main-box">
      <template v-if="detailId === -1">
        <el-table v-if="tableData.length > 0" :data="tableData" style="width: 100%" @row-click="rowClick">
          <el-table-column label="状态" width="120">
            <template slot-scope="scope">
              <i v-if="scope.row.examineState == 1" class="el-icon-time fs-mid-big theme-blue"></i>
              <i v-if="scope.row.examineState == 2" class="el-icon-success fs-mid-big"></i>
              <i v-if="scope.row.examineState == 3" class="el-icon-time fs-mid-big theme-blue"></i>
              <span v-if="scope.row.examineState == 1" style="margin-left: 10px">审核中</span>
              <span v-if="scope.row.examineState == 2" style="margin-left: 10px">审核通过</span>
              <span v-if="scope.row.examineState == 3" style="margin-left: 10px">审核未通过</span>
            </template>
          </el-table-column>
          <el-table-column prop="companyName" label="企业名称" width="300"> </el-table-column>
          <el-table-column prop="contactName" label="联系人" width="100"> </el-table-column>
          <el-table-column prop="copyrightName" label="版权作品名称" width="300"> </el-table-column>
          <el-table-column prop="needMoney" class-name="price" label="所需金额" width="160"> </el-table-column>
          <el-table-column prop="createTime" class-name="date" label="申请时间" width="150"> </el-table-column>
        </el-table>

        <div v-else class="width-100 flex-column justify-content-center align-items-center">
          <img src="../assets/image/empty.png" width="50%" />
        </div>
        <!--      分页-->
        <div class="pagination width-100 textAlign-c">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="5"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </template>
      <template v-else>
        <div class="padding-Tb-20">
          <p class="width-100 fs-big black margin-b fw-mid">{{ tableData[detailId].companyName }}</p>
          <div class="width-100 margin-b flex-row justify-content-start align-items-star flex-wrap">
            <p class="fs-mid black margin-b width-25">
              <span class="fs-mid darkgrey opacity-8">申请时间 </span> <span class="margin-l margin-r fs-mid darkgrey opacity-8">|</span
              >{{ tableData[detailId].createTime }}
            </p>
            <p class="fs-mid black margin-b width-25">
              <span class="fs-mid darkgrey opacity-8">申请人 </span> <span class="margin-l margin-r fs-mid darkgrey opacity-8">|</span
              >{{ tableData[detailId].applicantName }}
            </p>
            <p class="fs-mid black margin-b width-25">
              <span class="fs-mid darkgrey opacity-8">联系人 </span> <span class="margin-l margin-r fs-mid darkgrey opacity-8">|</span
              >{{ tableData[detailId].contactName }}
            </p>
            <p class="fs-mid black margin-b width-25">
              <span class="fs-mid darkgrey opacity-8">联系号码 </span> <span class="margin-l margin-r fs-mid darkgrey opacity-8">|</span
              >{{ tableData[detailId].contactMobile }}
            </p>
            <p class="fs-mid black margin-b width-50">
              <span class="fs-mid darkgrey opacity-8">版权作品 </span> <span class="margin-l margin-r fs-mid darkgrey opacity-8">|</span
              >{{ tableData[detailId].copyrightName }}
            </p>
            <p class="fs-mid theme-blue margin-b width-50">
              <span class="fs-mid darkgrey opacity-8">所需金额 </span> <span class="margin-l margin-r fs-mid darkgrey opacity-8">|</span
              >{{ tableData[detailId].needMoney }}元
            </p>
            <p v-if="tableData[detailId].remarks" class="margin-b width-100 line-height2">
              <span class="fs-mid darkgrey opacity-8">备注说明 </span> <span class="margin-l margin-r fs-mid darkgrey opacity-8">|</span
              ><span class="fs-mid black">{{ tableData[detailId].remarks }}</span>
            </p>
          </div>
          <div class="width-100 flex-row justify-content-start align-items-star flex-wrap">
            <el-image class="image" :src="item" v-for="(item, index) in tableData[detailId].picture" :key="index" fit="cover"></el-image>
          </div>
          <p class="width-100 margin-b margin-t flex-row justify-content-start align-items-center">
            <span class="left-block"></span><span class="fs-big black fw-mid">瑞安农商银行</span>
          </p>
          <div class="width-100 margin-b flex-column justify-content-center align-items-center">
            <img src="../assets/image/null.png" width="35%" class="margin-t" alt="" />
            <p class="theme-blue">银行审核中...</p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { financeServiceApplyListByUser } from '@/api/TranFinanceServiceApply';
import moment from 'moment';
import { dealMoney } from '@/util/helper';

export default {
  name: 'myFinancialService',
  components: {},
  props: {},
  data() {
    return {
      detailId: -1,
      currentPage: 1,
      total: 0,
      tableData: [],
      params: {
        pageNum: 1,
        pageSize: 5
      }
    };
  },
  created() {
    this.getFinanceServiceApplyListByUser();
  },
  methods: {
    rowClick(event) {
      // console.log(event.id);
      this.detailId = event.ind;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.params.pageNum = 1;
      this.params.pageSize = val;
      this.getFinanceServiceApplyListByUser();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.params.pageNum = val;
      this.getFinanceServiceApplyListByUser();
    },
    async getFinanceServiceApplyListByUser() {
      const list = await financeServiceApplyListByUser(this.params);
      this.total = list.total;
      this.tableData = list.rows.map((row, index) => {
        let picture = null;
        if (row.copyrightRegistration.copyrightImage) {
          picture = row.copyrightRegistration.copyrightImage.split(',').map((url) => `${process.env.VUE_APP_SERVE_PATH}${url}`);
        }
        return {
          ...row,
          ind: index,
          copyrightName: row.copyrightRegistration.title,
          createTime: moment(row.createTime).format('YYYY-MM-DD'),
          needMoney: dealMoney(row.needMoney),
          picture
        };
      });
      console.log('listasd:', list);
    }
  }
};
</script>

<style lang="less" scoped>
.el-icon-success {
  color: #5cb40e;
}
.image {
  width: 30%;
  margin-right: 3%;
  margin-bottom: 30px;
}
.left-block {
  display: inline-block;
  width: 6px;
  height: 24px;
  border-radius: 5px;
  background: #55aaff;
  margin-right: 15px;
}
/deep/.el-table__body .cell {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

/deep/.el-table__row td:first-child .cell {
  color: #666666;
  line-height: 2rem;
}
/deep/.el-table__row td:first-child .cell i {
  position: relative;
  top: 3px;
}
/deep/.el-table th.el-table__cell {
  background: #f7f9fa;
}

/deep/.el-table__body .price .cell {
  font-weight: normal;
  color: #449cff !important;
}
/deep/.el-table__body .date .cell {
  font-weight: normal;
  color: #666666 !important;
}
/deep/.el-table .el-table__cell {
  padding: 20px 0;
}
</style>
