import { render, staticRenderFns } from "./worksManagement.vue?vue&type=template&id=1386e0bb&scoped=true&"
import script from "./worksManagement.vue?vue&type=script&lang=js&"
export * from "./worksManagement.vue?vue&type=script&lang=js&"
import style0 from "./worksManagement.vue?vue&type=style&index=0&id=1386e0bb&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1386e0bb",
  null
  
)

export default component.exports