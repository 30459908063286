<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="-1"></top>
      <div class="container-box width-100 flex-row justify-content-spaceBetween align-items-star">
        <div class="left">
          <!-- <p
            class="leftList width-100 pointer flex-row justify-content-start align-items-center"
            v-for="(item, index) in leftList"
            :key="index"
            @click="item.title === '我的收藏' ? $router.push(item.url) : (activeIndex = index)"
          > -->
          <p
            class="leftList width-100 pointer flex-row justify-content-start align-items-center"
            v-for="(item, index) in leftList"
            :key="index"
            @click="activeIndex = index"
          >
            <span class="blue-block" :class="activeIndex == index ? 'opacity-1 fadeIn' : 'opacity-0 '"></span>
            <span class="fs-big" :class="activeIndex == index ? 'black fadeIn' : 'darkgrey opacity-8'">{{ item.title }}</span>
          </p>
        </div>
        <div class="right">
          <!--          版权管理-->
          <copyrightManagement v-if="activeIndex === 0"></copyrightManagement>
          <worksManagement v-else-if="activeIndex === 1"></worksManagement>
          <!--          我的消息-->
          <!-- <myMessage v-else-if="activeIndex === 1"></myMessage> -->
          <!--          我的需求-->
          <myNeeds v-else-if="activeIndex === 2"></myNeeds>
          <!--          已授权-->
          <myFavorites v-else-if="activeIndex === 3"></myFavorites>
          <!--          法律咨询-->
          <myLegalConsultation v-else-if="activeIndex === 4"></myLegalConsultation>
          <!--          金融服务-->
          <myFinancialService v-else></myFinancialService>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import copyrightManagement from '../components/copyrightManagement';
import worksManagement from '../components/worksManagement';
import myFavorites from '../components/myFavorites';
import myNeeds from '../components/myNeeds';
// import myAuthorized from '../components/myAuthorized';
import myLegalConsultation from '../components/myLegalConsultation';
import myFinancialService from '../components/myFinancialService';
export default {
  name: 'personalCenter',
  components: {
    top,
    bottom,
    copyrightManagement,
    worksManagement,
    myFavorites,
    // myMessage,
    myNeeds,
    // myAuthorized,
    myLegalConsultation,
    myFinancialService
  },
  data() {
    return {
      activeIndex: 0,
      leftList: [
        {
          title: '版权管理',
          url: ''
        },
        {
          title: '产品管理',
          url: ''
        },
        // {
        //   title: '我的消息',
        //   url: ''
        // },
        {
          title: '我的需求',
          url: ''
        },
        {
          title: '我的收藏',
          url: '/myFavorites'
        },
        // {
        //   title: '已授权',
        //   url: ''
        // },
        {
          title: '法律咨询',
          url: ''
        },
        {
          title: '金融服务',
          url: ''
        }
      ]
    };
  },
  created() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .container-box {
    padding: 40px 0;
    .left {
      border-radius: 15px;
      padding: 20px 0;
      background: white;
      width: 20%;
      position: sticky;
      top: 100px;
      z-index: 9;
      .leftList {
        padding: 15px 20px 15px 0;
        .blue-block {
          display: inline-block;
          width: 4px;
          height: 36px;
          border-radius: 0 5px 5px 0;
          background: #3f96ff;
          margin-right: 20px;
        }
      }
    }
    .right {
      border-radius: 15px;
      padding: 30px;
      background: white;
      width: 78%;
    }
  }
}
</style>
