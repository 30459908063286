<template>
  <div class="width-100">
    <p class="fs-super-big black fw-mid width-100 margin-b">我的需求</p>
    <div v-if="tableData.length > 0" class="main-box">
      <el-table :data="tableData" style="width: 100%" @row-click="rowClick">
        <el-table-column label="状态" width="120">
          <template slot-scope="scope">
            <i v-if="scope.row.audit === '1'" class="el-icon-success fs-mid-big"></i>
            <i v-else-if="scope.row.audit === '2'" class="el-icon-error fs-mid-big"></i>
            <i v-else class="el-icon-time fs-mid-big theme-blue"></i>
            <span v-if="scope.row.audit === '1'" style="margin-left: 10px">审核通过</span>
            <span v-else-if="scope.row.audit === '2'" style="margin-left: 10px">审核失败</span>
            <span v-else style="margin-left: 10px">审核中</span>
          </template>
        </el-table-column>
        <el-table-column class-name="companyName" label="企业信息" width="400">
          <template slot-scope="scope">
            <div class="width-100 flex-row justify-content-around align-items-center">
              <el-image class="image" :src="scope.row.picture" fit="cover"></el-image>

              <p class="width-75">{{ scope.row.companyName }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="copyrightTypeName" label="版权分类" width="150" align="center"> </el-table-column>
        <el-table-column prop="copyrightUsageName" label="版权用途" width="130" align="center"> </el-table-column>
        <el-table-column prop="createTime" class-name="date" label="发布时间" width="130" align="center"> </el-table-column>
        <el-table-column label="操作" class-name="operation" width="130" align="center">
          <template slot-scope="scope">
            <span class="margin-r pointer" @click="$router.push(`/releaseDemand?id=${scope.row.id}`)">修改</span>
            <span class="margin-l pointer" @click="deleteDemand(scope.row.id)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else class="width-100 flex-column justify-content-center align-items-center">
      <img src="../assets/image/empty.png" width="50%" />
    </div>
    <!--      分页-->
    <div class="pagination width-100 textAlign-c">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getMyDemand, deleteMyDemand } from '@/api/tranCopyrightDemand';
import { getDict } from '@/api/dict';
import moment from 'moment';
import { getTextByValue } from '@/util/helper';

export default {
  name: 'myNeeds',
  components: {},
  props: {},
  data() {
    return {
      currentPage: 1,
      detailId: -1,
      total: 0,
      tableData: [],
      params: {
        pageNum: 1,
        pageSize: 10
      },
      tranCopyrightUsageDist: []
    };
  },
  async created() {
    await this.setDist();
    this.getMyDemandList();
  },
  methods: {
    async setDist() {
      return Promise.all([getDict('tran_copyright_usage')]).then((rows) => {
        this.tranCopyrightUsageDist = rows[0];
      });
    },
    rowClick(event) {
      // console.log(event.id);
      this.detailId = event.id;
    },
    async getMyDemandList() {
      const list = await getMyDemand(this.params);
      this.total = list.total;
      console.log(list);
      // const that = this;
      this.tableData = list.rows.map((row) => {
        return {
          ...row,
          picture: row.otherFiles ? `${process.env.VUE_APP_SERVE_PATH}${row.otherFiles.split(',')[0]}` : null,
          copyrightUsageName: getTextByValue(this.tranCopyrightUsageDist, row.copyrightUsage),
          createTime: moment(row.createTime).format('YYYY-MM-DD')
        };
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.params.pageNum = 1;
      this.params.pageSize = val;
      this.getMyDemandList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.params.pageNum = val;
      this.getMyDemandList();
    },
    async deleteDemand(id) {
      await deleteMyDemand(id);
      this.getMyDemandList();
    }
  }
};
</script>

<style lang="less" scoped>
.el-icon-success {
  color: #5cb40e;
}
.el-icon-error {
  color: #ff0000;
}
.image {
  width: 25%;
  height: 70px;
  margin-right: 3%;
}
.left-block {
  display: inline-block;
  width: 6px;
  height: 24px;
  border-radius: 5px;
  background: #55aaff;
  margin-right: 15px;
}
/deep/.el-table__body .cell {
  font-size: 18px;
  color: #333333;
}
/deep/.el-table__body .companyName .cell p {
  font-weight: bold;
  font-size: 18px;
}

/deep/.el-table__row td:first-child .cell {
  color: #666666;
  line-height: 2rem;
}
/deep/.el-table__row td:first-child .cell i {
  position: relative;
  top: 3px;
}
/deep/.el-table th.el-table__cell {
  background: #f7f9fa;
}

/deep/.el-table__body .operation .cell {
  font-weight: normal;
  color: #449cff !important;
}
/deep/.el-table__body .date .cell {
  font-weight: normal;
  color: #666666 !important;
}
/deep/.el-table .el-table__cell {
  padding: 20px 0;
}
</style>
