<template>
  <div class="width-100">
    <p class="fs-super-big black fw-mid width-100 margin-b">法律咨询</p>
    <div class="main-box">
      <!--      咨询列表-->
      <template v-if="detailId === -1">
        <div v-if="list.length > 0">
          <div class="width-100 list" v-for="(item, index) in list" :key="index" @click="detailId = index">
            <p class="width-100 cut-text fs-big black fw-mid">
              <el-tag v-if="item.replyState == 1" effect="plain" size="mini">未回复</el-tag>
              <el-tag v-if="item.replyState == 2" effect="dark" size="mini">已回复</el-tag>
              {{ item.title }}
            </p>
            <p class="width-100 cut-text2 line-height2 margin-ts margin-bs fs-mid darkgrey opacity-8">{{ item.content }}</p>
            <p class="width-100 flex-row justify-content-spaceBetween align-items-center">
              <span class="darkgrey opacity-5">提问时间：{{ item.createTime }}</span>
              <span class="theme-blue opacity-5 pointer">查看详情>></span>
            </p>
          </div>
        </div>
        <div v-else class="width-100 flex-column justify-content-center align-items-center">
          <img src="../assets/image/empty.png" width="50%" />
        </div>
        <!--      分页-->
        <div class="pagination width-100 textAlign-c">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="5"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </template>
      <!--      咨询详情-->
      <template v-else>
        <div class="padding-Tb-20">
          <p class="width-100 fs-big black margin-bs fw-mid">{{ list[detailId].title }}</p>
          <p class="width-100 fs-mid darkgrey opacity-8 margin-b">{{ list[detailId].createTime }} 提问</p>
          <p class="width-100 fs-mid black margin-b line-height2">{{ list[detailId].content }}</p>
          <div v-if="list[detailId].images" class="width-100 flex-row justify-content-center align-items-center flex-wrap">
            <!-- <el-image class="image" :src="item" v-for="(item, index) in list[detailId].picture" :key="index" fit="cover"></el-image> -->
            <el-image class="image" :src="list[detailId].images" fit="cover"></el-image>
          </div>
          <div class="width-100 margin-t margin-b flex-row justify-content-start align-items-star">
            <span class="left-block"></span>
            <div class="width-100">
              <p class="width-100 margin-bs flex-row justify-content-start align-items-center">
                <span class="fs-big black fw-mid">{{ list[detailId].lawFirms.name }}</span>
              </p>
              <p v-if="list[detailId].replyState == 2" class="width-100 fs-mid darkgrey opacity-8">{{ list[detailId].replyTime }} 回复</p>
              <div v-else class="width-100 flex-column justify-content-center align-items-center">
                <img src="../assets/image/null.png" width="35%" class="margin-t" alt="" />
                <p class="theme-blue">回答中...</p>
              </div>
            </div>
          </div>
          <p class="width-100 fs-mid black margin-b line-height2">{{ list[detailId].replyContent }}</p>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { legalQuestionListByUser } from '@/api/TranLegalQuestion';
import { getTextByValue } from '@/util/helper';
import { getDict } from '@/api/dict';

// TODO 缺少暂未回复的图片

export default {
  name: 'myLegalConsultation',
  components: {},
  props: {},
  data() {
    return {
      detailId: -1,
      currentPage: 1,
      total: 0,
      list: [
        // {
        //   ifReply: false,
        //   replyCompany: '北京安理（杭州）律师事务所',
        //   replyDate: '2024-03-24',
        //   replyDetail:
        //     '形象标识的设计创意来源于南宋时期王称所撰《东都事略》一书，书中有牌记：“眉山程舍人宅刊行，已申上 司不许覆板”，这是迄今为止发形象标识的设计创意来源于南宋时期王称所撰《东都事略》一书，书中有牌记：“眉山程舍人宅刊行，已申上 司不许覆板”，这是迄今为止发',
        //   title: '1国家知识产权局办公室关于开展2023年全国专利调查工作的通知',
        //   detail:
        //     '全国专利调查是支撑知识产权强国建设的重要基础性工作全国专利调查是支撑知识产权强国建设的重要基础性工作,全国专利调查是支撑知识产权强国建设的重要基础性工作全国专利调查是支撑知识产权强国建设的重要基础性工作，自2008年起已连续开展15年，《中国专利调查报告》自2015年起 已连续',
        //   date: '2023-01-02',
        //   picture: [
        //     'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
        //     'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg'
        //   ] //照片
        // },
        // {
        //   ifReply: true,
        //   replyCompany: '北京安理（杭州）律师事务所',
        //   replyDate: '2024-03-24',
        //   replyDetail:
        //     '形象标识的设计创意来源于南宋时期王称所撰《东都事略》一书，书中有牌记：“眉山程舍人宅刊行，已申上 司不许覆板”，这是迄今为止发形象标识的设计创意来源于南宋时期王称所撰《东都事略》一书，书中有牌记：“眉山程舍人宅刊行，已申上 司不许覆板”，这是迄今为止发',
        //   title: '1国家知识产权局办公室关于开展2023年全国专利调查工作的通知',
        //   detail:
        //     '全国专利调查是支撑知识产权强国建设的重要基础性工作全国专利调查是支撑知识产权强国建设的重要基础性工作,全国专利调查是支撑知识产权强国建设的重要基础性工作全国专利调查是支撑知识产权强国建设的重要基础性工作，自2008年起已连续开展15年，《中国专利调查报告》自2015年起 已连续',
        //   date: '2023-01-02',
        //   picture: [
        //     'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
        //     'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg'
        //   ] //照片
        // },
        // {
        //   ifReply: false,
        //   replyCompany: '北京安理（杭州）律师事务所',
        //   replyDate: '2024-03-24',
        //   replyDetail:
        //     '形象标识的设计创意来源于南宋时期王称所撰《东都事略》一书，书中有牌记：“眉山程舍人宅刊行，已申上 司不许覆板”，这是迄今为止发形象标识的设计创意来源于南宋时期王称所撰《东都事略》一书，书中有牌记：“眉山程舍人宅刊行，已申上 司不许覆板”，这是迄今为止发',
        //   title: '1国家知识产权局办公室关于开展2023年全国专利调查工作的通知',
        //   detail:
        //     '全国专利调查是支撑知识产权强国建设的重要基础性工作全国专利调查是支撑知识产权强国建设的重要基础性工作,全国专利调查是支撑知识产权强国建设的重要基础性工作全国专利调查是支撑知识产权强国建设的重要基础性工作，自2008年起已连续开展15年，《中国专利调查报告》自2015年起 已连续',
        //   date: '2023-01-02',
        //   picture: [
        //     'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
        //     'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg'
        //   ] //照片
        // },
        // {
        //   ifReply: true,
        //   replyCompany: '北京安理（杭州）律师事务所',
        //   replyDate: '2024-03-24',
        //   replyDetail:
        //     '形象标识的设计创意来源于南宋时期王称所撰《东都事略》一书，书中有牌记：“眉山程舍人宅刊行，已申上 司不许覆板”，这是迄今为止发形象标识的设计创意来源于南宋时期王称所撰《东都事略》一书，书中有牌记：“眉山程舍人宅刊行，已申上 司不许覆板”，这是迄今为止发',
        //   title: '1国家知识产权局办公室关于开展2023年全国专利调查工作的通知',
        //   detail:
        //     '全国专利调查是支撑知识产权强国建设的重要基础性工作全国专利调查是支撑知识产权强国建设的重要基础性工作,全国专利调查是支撑知识产权强国建设的重要基础性工作全国专利调查是支撑知识产权强国建设的重要基础性工作，自2008年起已连续开展15年，《中国专利调查报告》自2015年起 已连续',
        //   date: '2023-01-02',
        //   picture: [
        //     'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
        //     'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg'
        //   ] //照片
        // },
        // {
        //   ifReply: false,
        //   replyCompany: '北京安理（杭州）律师事务所',
        //   replyDate: '2024-03-24',
        //   replyDetail:
        //     '形象标识的设计创意来源于南宋时期王称所撰《东都事略》一书，书中有牌记：“眉山程舍人宅刊行，已申上 司不许覆板”，这是迄今为止发形象标识的设计创意来源于南宋时期王称所撰《东都事略》一书，书中有牌记：“眉山程舍人宅刊行，已申上 司不许覆板”，这是迄今为止发',
        //   title: '1国家知识产权局办公室关于开展2023年全国专利调查工作的通知',
        //   detail:
        //     '全国专利调查是支撑知识产权强国建设的重要基础性工作全国专利调查是支撑知识产权强国建设的重要基础性工作,全国专利调查是支撑知识产权强国建设的重要基础性工作全国专利调查是支撑知识产权强国建设的重要基础性工作，自2008年起已连续开展15年，《中国专利调查报告》自2015年起 已连续',
        //   date: '2023-01-02',
        //   picture: [
        //     'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
        //     'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
        //     'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg'
        //   ] //照片
        // }
      ],
      params: {
        pageNum: 1,
        pageSize: 5
      },
      tranLegalQuestionReplyStateDist: []
    };
  },
  async created() {
    await this.setDist();
    this.getLegalQuestionListByUser();
  },
  methods: {
    async setDist() {
      return Promise.all([getDict('tran_legal_question_reply_state')]).then((rows) => {
        this.tranLegalQuestionReplyStateDist = rows[0];
      });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.params.pageNum = 1;
      this.params.pageSize = val;
      this.getLegalQuestionListByUser();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.params.pageNum = val;
      this.getLegalQuestionListByUser();
    },
    async getLegalQuestionListByUser() {
      const list = await legalQuestionListByUser(this.params);
      this.total = list.total;
      this.list = list.rows.map((row) => {
        return {
          ...row,
          replyStateName: getTextByValue(this.tranLegalQuestionReplyStateDist, row.replyState),
          images: row.images ? `${process.env.VUE_APP_IMAGE_PREFIX}${row.images}` : null
        };
      });
      // console.log('list:', list);
    }
  }
};
</script>

<style lang="less" scoped>
.list {
  padding: 20px 0;
  border-bottom: 1px solid #eeeeee;
}
.image {
  width: 48%;
  margin: 0 2% 20px 0;
  height: 300px;
}
.el-tag--plain {
  background-color: #edf6ff;
  border-color: #edf6ff;
}
.el-tag {
  position: relative;
  top: -3px;
}
.left-block {
  display: inline-block;
  width: 6px;
  height: 24px;
  border-radius: 5px;
  background: #55aaff;
  margin-right: 15px;
  position: relative;
  top: 5px;
}
</style>
