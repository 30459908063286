import request from '@/util/request';

/**
 * 版权交易金融服务申请 接口
 * @Author Hubx
 */

// 免登录 获取详情
export function financeServiceApplyInfo(id) {
  return request({
    url: `/tran/TranFinanceServiceApply/ua/` + id,
    method: 'get'
  });
}

// 免登录 获取列表
export function financeServiceApplyList(params) {
  return request({
    url: `/tran/TranFinanceServiceApply/ua/list`,
    method: 'get',
    params: params
  });
}

// 免登录 获取列表
export function financeServiceApplyListByUser(params) {
  return request({
    url: `/tran/TranFinanceServiceApply/ua/list/user`,
    method: 'get',
    params: params
  });
}

// 免登录 保存记录
export function financeServiceApplySave(data) {
  return request({
    url: `/tran/TranFinanceServiceApply/ua/saveOrUpdate`,
    method: 'post',
    data: data
  });
}
